<template>
  <div>
    <a href="https://beian.miit.gov.cn/">冀ICP备2024068590号</a>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>
<style lang="less" scoped>
a {
  font-size: 0.2rem;
  color: #afafaf;
}
</style>
