<template>
  <div>
    <van-config-provider :theme-vars="themeVars">
      <van-search
        v-model="data.value"
        show-action
        clear-icon="clear"
        clear-trigger="always"
        placeholder="输入你要搜索的商品名称"
        @search="onSearch"
        @clear="onClear"
        class="van_search"
        background="#ff0b3f"
        :radius="15"
      >
        <template #action>
          <van-button @click="onClickButton">搜索</van-button>
          <van-button @click="onCancel">返回</van-button>
        </template>
      </van-search>
    </van-config-provider>
  </div>
</template>

<script setup>
import { defineEmits, reactive, toRefs, ref } from "vue";
import { Toast } from "vant";
import axios from "axios";

const emit = defineEmits(["search_evnet", "search_cancel"]);
const data = reactive({
  value: ref(""),
});
// const value = ref('');

const themeVars = reactive({});
const onSearch = (val) => {
  searchPost(val);
};
function searchPost(content) {
  const obj = { content: "" };
  obj.content = content.trim();
  if (obj.content != "") {
    axios
      .post("/api/search", obj)
      .then((res) => {
        // console.log(res);
        if (res.data.code == 1) {
          // data.commondities = res.data.msg.data;
          emit("search_evnet", res.data.msg.data);
        } else {
          Toast(res.data.msg);
        }
      })
      .catch((e) => {
        Toast("搜索失败!");
        // console.log(e);
      });
  } else {
    Toast("搜索内容不能为空!");
  }
}
const onClickButton = () => searchPost(data.value);
const onClear = () => {
  data.value = "";
};
const onCancel = () => {
  // Toast("onCancel");
  emit("search_cancel");
};
</script>
<style lang='less' scoped>
/deep/.van-search {
  height: 60px;
  display: flex;
  padding-top: 30px;
  .van-search__content {
    --van-border-radius-sm: 10px;

  }
  .van-search__action{
    display: flex;
    justify-content: center;
    .van-button {
    box-sizing: border-box;
    margin: 0 5px;
    font-size: 8px;
    font-weight: 600;
    height: 25px;
    border-radius: 8px;
  }
  }
  
}


</style>