<template>
  <div
    class="commodity"
    v-for="(item, index) in commondites"
    :key="index"
    v-show="item.isToDay"
  >
    <StoreOnlyItem :item="item"  />
  </div>
</template>

<script setup>
import { Toast } from "vant";
import { onMounted, onUpdated , defineProps } from "vue";
import StoreOnlyItem from "./StoreOnlyItem.vue";
// const { toClipboard} = useClipboard();
const props = defineProps({
  commondites: Object,
  curDay: String,
});


// onMounted(()=>{
//   console.log(props.commondites,props.curDay,"storeItem.onMounted");
// });
onUpdated(() => {
  props.commondites.forEach((element) => {
    let creatDay = new Date(Number(element.add_time) * 1000);
    // console.log(creatDay);
    if (props.curDay == null) {
      element.isToDay = true;
    } else if (
      formatDate(
        creatDay.getFullYear(),
        creatDay.getMonth() + 1,
        creatDay.getDate()
      ) == props.curDay
    ) {
      element.isToDay = true;
    } else {
      element.isToDay = false;
    }
  });

});
function formatDate(year, month, day) {
  const y = year;
  let m = month;
  if (m < 10) m = `0${m}`;
  let d = day;
  if (d < 10) d = `0${d}`;
  return `${y}-${m}-${d}`;
}
</script>
<style lang='less' scoped>
.van-image {
  border-radius: 3px;
}

.commodity {
  box-sizing: border-box;
  width: 49%;
  height: 300px;
  padding: 3px;
  border-radius:3px;
  background-color: #f3e5e5;
  margin: 3px 1px;
  overflow: hidden;
}
</style>