<template>
  <div class="date">
    <div class="week">
      <van-button class="vanbutton" icon="arrow-left" @click="swipeRight()" />
      <div class="weeks">
        <!-- 星期 -->
        <ul class="weekdays">
          <li>一</li>
          <li>二</li>
          <li>三</li>
          <li>四</li>
          <li>五</li>
          <li>六</li>
          <li>日</li>
        </ul>
        <!-- 日期 -->
        <!-- 左滑、右滑 -->

        <ul class="days">
          <li
            v-for="(day, index) in data.days"
            :key="index"
            @click="pick(day.date)"
          >
            <!--本月-->
            <!-- {{ day.isHas }}
            {{ day. date }} 
              如果不是当月 会处罚这个
          -->
            <span
              v-if="day.date.getMonth() + 1 != data.currentMonth"
              class="other-month"
              @click="dayClick(day.date)"
              
              >{{ day.date.getDate() }}</span
            >
            <!-- 否则显示这个 -->
            <span v-else>
              <!--如果是今天显示的内容-->
              <span
                v-if="
                  day.date.getFullYear() == data.curDay.getFullYear() &&
                  day.date.getMonth() == data.curDay.getMonth() &&
                  day.date.getDate() == data.curDay.getDate()
                "
                class="active"
                @click="dayClick(day.date)"
                >{{ day.date.getDate() }}</span
              >
              <!--如果不是今天显示的情况-->

              <span @click="dayClick(day.date)" v-else>{{
                day.date.getDate()
              }}</span>
            </span>
            <div v-show="day.isHas" class="bt_red"></div>
            <!-- <div v-show ="!day.isHas"></div> -->
          </li>
        </ul>
      </div>
      <van-button class="vanbutton" icon="arrow" @click="swipeLeft()" />
    </div>
    <div class="month">
      <p>{{ data.currentYear }}年{{ data.currentMonth }}月</p>
    </div>
  </div>
</template>

<script setup >
import {
  defineEmits,
  ref,
  onMounted,
  defineProps,
  reactive,
  onUpdated,
  watch,
} from "vue";

const props = defineProps({
  dateList: {
    type: Object,
    default: () => ({
      date: new Date(),
      isHas: false,
    }),
  },
});
// console.log("props",props.days);
const emit = defineEmits(["child-event", "child-event-one"]);
// const emit2 = defineEmits(["child-event-one"]);
let data = reactive({
  currentYear: 1970,
  currentMonth: 1,
  currentDay: 1,
  currentWeek: 1,
  curDay: 1,
  days: [],
  dataDay: [],
});
function objDate(date, isHas) {
  this.date = date;
  this.isHas = isHas;
}

watch(
  () => props.dateList,
  (newVal, oldVal) => {
    // console.log('count changed:', newVal, oldVal);
    dayIsHas();
  },
  {
    deep: true, // 开启深度监听
    immediate: true,
  }
);

function dayClick(date) {
  // console.log(date);
  data.curDay = date;
  data.currentMonth = date.getMonth()+1;
  data.currentYear = date.getFullYear();
}

function formatDate(year, month, day) {
  const y = year;
  let m = month;
  if (m < 10) m = `0${m}`;
  let d = day;
  if (d < 10) d = `0${d}`;
  return `${y}-${m}-${d}`;
}
//初始化现实当前的日期
function initData(cur) {
  let date = "";

  if (data.curDay) {
    data.curDay = new Date();
  }

  if (cur) {
    date = new Date(cur);
  } else {
    date = new Date();
  }
  data.currentDay = date.getDate();
  data.currentYear = date.getFullYear();
  data.currentMonth = date.getMonth() + 1;
  data.currentWeek = date.getDay();

  if (data.currentWeek === 0) {
    data.currentWeek = 7;
  }
  const str = formatDate(data.currentYear, data.currentMonth, data.currentDay);
  // console.log(data.days.length);
  data.days.length = 0;
  data.dataDay.length = 0;

  //根据今天是周几判断 找到前几天的日期
  for (let i = data.currentWeek - 1; i >= 0; i -= 1) {
    const d = new Date(str);
    // console.log(d,i,cur);
    d.setDate(d.getDate() - i);
    // let obj = dayIsHas(d);
    let obj = new objDate(d, false);
    data.days.push(obj);
    data.dataDay.push(
      formatDate(d.getFullYear(), d.getMonth() + 1, d.getDate())
    );
  }

  //找到今天后面几天的日期
  for (let i = 1; i <= 7 - data.currentWeek; i += 1) {
    const d = new Date(str);
    d.setDate(d.getDate() + i);
    // let obj = dayIsHas(d);
    let obj = new objDate(d, false);
    data.days.push(obj);
    data.dataDay.push(
      formatDate(d.getFullYear(), d.getMonth() + 1, d.getDate())
    );
  }

  // console.log(data.days,"data.days");

  emitToParent();
}
//判断指定日期是否有数据
function dayIsHas() {
  for (let i = 0; i < data.days.length; i++) {
    const toDay = data.days[i].date;
    for (let index = 0; index < props.dateList.length; index++) {
      let element = props.dateList[index];
      let tmpDate = new Date(Number(element.date) * 1000);
      let tmpFmtDay = formatDate(
        tmpDate.getFullYear(),
        tmpDate.getMonth() + 1,
        tmpDate.getDate()
      );
      let paramFmtDay = formatDate(
        toDay.getFullYear(),
        toDay.getMonth() + 1,
        toDay.getDate()
      );
      // console.log(tmpFmtDay, paramFmtDay, tmpFmtDay == paramFmtDay, "obj");
      if (tmpFmtDay == paramFmtDay) {
        data.days[i].isHas = true;
        break;
      }
    }
    
  }
  // console.log(data.days,"data.days");
  // return obj;
}

//上一周
function weekPre() {
  const d = data.days[0].date;
  d.setDate(d.getDate() - 7);
  // console.log(props.dateList,"weekPre");
  initData(d);
}
//下一周
function weekNext() {
  const d = data.days[6].date;
  d.setDate(d.getDate() + 7);
  // console.log(props.dateList,"weekNext");
  initData(d);
}

function pickPre(year, month) {
  const d = new Date(formatDate(year, month, 1));
  d.setDate(0);
  initData(formatDate(d.getFullYear(), d.getMonth() + 1, 1));
}

function pickNext(year, month) {
  const d = new Date(formatDate(year, month, 1));
  d.setDate(35);
  initData(formatDate(d.getFullYear(), d.getMonth() + 1, 1));
}

function pick(date) {
  emitToParent2(
    formatDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
  );
}
//提交给父组件？
function emitToParent() {
  // console.log("emit,child-event");

  emit("child-event", data.dataDay);
}
function emitToParent2(date) {
  // console.log("emit,child-eventt-one",date);
  emit("child-event-one", date);
}
function swipeLeft() {
  weekNext();
}
function swipeRight() {
  weekPre();
}

onMounted(() => {
  initData(null);
  pick(data.curDay);
  // console.log("calendar的 mounted");
});

// onUpdated(() => {
//   console.log("onUpdated", props.dateList);
// });
</script>

<style lang="less">
.date {
  width: 100%;
  color: #333;
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3px;
  font-size: 10px;
  .vanbutton {
    border-color: #fff;
    top: 5px;
    display: block;
    width: 10%;
  }
  .week {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .weeks {
      width: 100%;
    }
  }
  .month {
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
  }

  .weekdays {
    display: flex;
    font-size: 14px;
    margin-top: 10px;

    li {
      font-size: 10px;
      flex: 1;
      text-align: center;
      color: #aaa;
    }
  }

  .days {
    display: flex;
    justify-content: center;

    li {
      flex: 1;
      margin-top: 5px;
      line-height: 30px;
      width: 30px;
      height: 30px;
      align-self: center;
      display: block;

      span {
        display: block;
      }
      .active {
        display: inline-block;
        width: 30px;
        height: 30px;
        color: #fff;
        border-radius: 50%;
        background-color: #4fc08d;
      }

      .haslocus {
        background-color: #ddd;
      }

      .other-month {
        color: #e4393c;
      }
    }
  }
  .bt_red {
    text-align: center;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: red;
    margin-top: 3px;
    position: relative;
    left: 48%;
  }
}
</style>