<template>
  <div class="item_main">
    <van-image class="header_img" :src="item.pict_url" />

    <!-- <van-text-ellipsis
      class="comTitle marginset flex_left"
      :content="item.title"
      rows="1"
    /> -->
    <!-- <div
      :style="`color:${defaultTheme.title.fontColor}; background-color:${defaultTheme.title.bgColor}`"
      class="comTitle marginset flex_left"
    > -->
    <div class="comTitle marginset flex_left">
      {{ item.title }}
    </div>
    <!-- <div class="comTitle marginset flex_left">{{ cmpTitle }}</div> -->

    <div class="comPice marginset flex_left">
      <div v-if="item.live_price">
        <span>直播价</span> <span>{{ item.live_price }}</span>
      </div>
      <div v-else><span>直播价 </span> <span>¥ ???</span></div>
    </div>
    <div id="discount" class="discount marginset flex_left">
      <div v-if="item.per_face">
        <div v-show="item.per_face" class="discountItems">
          <div><span>领内部券</span> </div>
          <div><span>比直播间便宜</span></div>
          <div class="dic_perface"><span>{{ item.per_face }}元</span></div>
          <!-- {{ item.per_face }} -->
        </div>
      </div>
      <div v-else-if="defaultTheme.discounts.count == 0">
        <div v-for="(dis, index) in defaultTheme.discounts" :key="index">
          <div class="discountItems">
            <div>内部券 | {{ dis }}</div>
          </div>
          <!-- <div v-if="dis" class="discountItems">
          内部券 | {{ dis }}
        </div>
        <p v-show="!item.per_face" class="discountItems">内部券 | 已领完</p>
        <div v-else class="discountItems">内部券 | 已领完</div> -->
        </div>
      </div>
      <div v-else class="discountItems">  <div><span>内部券 | 已领完</span> </div></div>
    </div>
    <van-button

      class="van_btn marginset"
      @click="copy(item.password_simple)"
      ><span>点击领券</span> </van-button
    >
  </div>
</template>

<script >
import useClipboard from "vue-clipboard3";
import { Toast } from "vant";
import { defineComponent, computed, reactive, toRefs, onMounted } from "vue";
import { RESOLVE_FILTER } from "@vue/compiler-core";

export default defineComponent({
  props: {
    item: Object,
    theme: Object,
  },

  setup(props) {
    const data = reactive({
      defaultTheme: {
        title: {
          bgColor: "#000",
          fontColor: "#fff",
        },
        discounts: [],
      },
    });
    const { toClipboard } = useClipboard();
    const copy = async (password_simple) => {
      // console.log(password_simple);
      // console.log(password_simple, "copy");
      if (password_simple == null) {
        // await toClipboard(password_simple);
        Toast("优惠已经领完了");
      } else {
        try {


        let tmp =  password_simple.replace('￥','');
        tmp = '7¥' + tmp + '/ CZ0001'

          await toClipboard(tmp);
          // 复制完成
          Toast("优惠券已经领取到您的淘宝中，请您打开手机淘宝查收！");
        } catch (e) {
          Toast("复制失败，请查看手机是否给了剪切板权限");
          // console.log("复制失败", e);
        }
      }
    };
    function addDragListener() {
      let flag = false;
      let startX;
      let scrollLeft;
      let htmlContaienr = document.getElementById("discount");

      htmlContaienr.addEventListener("touchstart", function (event) {
        flag = true;
        // 获取触碰起始位置的X坐标
        startX = event.changedTouches[0].clientX;
        // this指向htmlContainer该元素
        scrollLeft = this.scrollLeft;
      });
      htmlContaienr.addEventListener("touchmove", function (event) {
        if (flag) {
          // 获取移动后的终点位置x坐标
          let endX = event.changedTouches[0].clientX;
          // 计算移动前后X坐标差值，即滚动距离
          // 往左边滑动，即页面整体左移：startX>endX
          let scrollX = startX - endX;
          // 设置距离为当前距左距离+滚动距离
          this.scrollLeft = scrollLeft + scrollX;
        }
      });
      htmlContaienr.addEventListener("touchend", function () {
        flag = false;
      });
    }
    onMounted(() => {
      addDragListener();
    });
    const cmpTitle = computed(() => {
      let res = "";
      let maxLength = 14;
      let titleLength = getByteLength(props.item.title);
      if (titleLength >= maxLength) {
        res = props.item.title;
        maxLength = maxLength > titleLength ? titleLength : maxLength;
        res = res.slice(0, maxLength);

        res = res + "...";
        // console.log(res, "res.slice");
      } else {
        res = props.item.title;
      }
      // console.log("computed", res, props.item.title);
      return res;
    });

    function getByteLength(str) {
      let len = 0;
      for (let i = 0; i < str.length; i++) {
        const code = str.charCodeAt(i);
        if (code >= 0x4e00 && code <= 0x9fff) {
          // 中文字符的Unicode范围是0x4e00到0x9fff
          len += 3; // 中文字符长度是3
        } else {
          len++; // 其他字符长度是1
        }
      }
      return len;
    }
    // console.log(cmpTitle);
    return {
      copy,
      cmpTitle,
      ...toRefs(data),
    };
  },
});
</script>

<style lang='less' scoped>
.item_main {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  .header_img {
    width: 100%;
    height: 100%;
  }
  .comTitle {
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: 800;
    text-indent: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .marginset {
    border-radius: 5px;
    margin-top: 1px;
    padding: 2px;
    box-sizing: border-box;
    text-align: left;

    // align-self: center;
  }
  .comPice {
    // 直播价
    // background-color: #f0f0f0;

    width: 100%;
    // color: #404040;
    color: black;
    background-color: #ff0b3f;
    font-size: 10px;
    box-sizing: border-box;
    div {
      height: 25px;
      display: flex;
      justify-content: space-between;
      span {
        align-self: center;
        display: block;
        background-color: #fff;
        padding: 3px;
        margin: 0 5px;
        font-weight: 600;
        letter-spacing: 1.5px;
        border-radius: 3px;
      }
    }
  }
  .discount {
    width: 100%;
    background-color: #ff0b3f;
    font-size: 10px;
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
    .discountItems {   //优惠券
      display: flex;   
      justify-content: space-between;
      color: aliceblue;
      overflow: hidden;
      box-sizing: border-box;
      align-items: center;
      flex-flow: nowrap;
      height: 25px;

      div{
        flex:1;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        height: 10px;
        margin-right: 4px;
      }

     span{
        display: inline-block;
        width: max-content;
      }
      div:first-child {
        background-color: #fff;
        color: #000;
        padding: 3px;
        margin: 0 5px;
        font-weight: 600;
        letter-spacing: 1px;
        border-radius: 3px;

      }
      span:nth-child(2){
        font-size: 10px;
      }
      div:last-child{
        box-sizing: border-box;
        height: 80%;
        letter-spacing: -0.1px;
        // color: #000;
        font-weight: 800;
        color: #fff;
        // padding: 3px;
        // border-radius: 5px;
        margin-right: 0 1px;
      }
    }
  }
  .van_btn{
    height: 25px;
    margin: 10px 0 0 0;
    width:60%;
    color: #fff;
    background-color: #ff0b3f;
    border-radius: 10px;
    align-self: center;
    display: flex;
    justify-content: center;
    span{
      display: block;
      letter-spacing: 5px;
      font-size: 16px;
    }
  }
}
</style>