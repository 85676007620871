<template>
  <router-view />
</template>
<script>
import { onMounted } from "vue";
export default {
  name: "App",

  setup() {
    onMounted(() => {
      //为pc则用iframe把移动端页面显示到页面中间
      let userAgentInfo = window.navigator.userAgent;
      let Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      //pc
      if (
        !Agents.some((item) =>
          userAgentInfo.toLowerCase().includes(item.toLowerCase())
        ) &&
        !(self.frameElement && self.frameElement.tagName == "IFRAME")
      ) {
        // 检查页面是否已经有iframe   这个判断让页面拥有只保留一个iframe
        let ifrTag = document.getElementsByTagName("iframe")[0];
        if (ifrTag) {
          // 如果有，则删除它
          ifrTag.remove();
        } else {
          let ifrTag = document.createElement("iframe");
          document.body.innerHTML = "";
          ifrTag.setAttribute("src", window.location.href);
          let styleObj = {
            width: "480px",
            height: "920px",
            position: "absolute",
            border: 0,
            left: "50%",
            transform: "translateX(-50%)",
          };
          Object.entries(styleObj).forEach(([key, value]) => {
            ifrTag.style[key] = value;
          });
          document.body.append(ifrTag);
        }
      }
      // const axios = inject('$axios')
      // axios.get("/user/userinfo").then((res) => {
      //   console.log(res, "返回数据 ");
      // });
      // axios.get("/account?username=张三&password=12345678").then((res) => {
      //   console.log(res, "返回数据 ");
      // });
    });
  },
};
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
