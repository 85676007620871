<template>
  <div v-show="searchContent.isSearch == false">
    <van-tabs
      class="van-tabs"
      v-model:active="tabs_active"
      @click-tab="onClickTab"
    >
      <van-tab
        v-for="item in resData"
        :key="item.id"
        :title="item.group_name"
        :name="item.id"
      >
        <!-- <Calender2/>
      <StoreItem></StoreItem> -->
        <Calender2
          @child-event="childEvent"
          @child-event-one="childEventOnly"
          :dateList="item.dateList"
        />
        <div class="store_item">
          <StoreItem
            :commondites="item.commondites"
            :curDay="curDay"
          ></StoreItem>
        </div>
      </van-tab>
    </van-tabs>
  </div>
  <div v-show="searchContent.isSearch">
    <div class="store_item">
      <StoreItem
        :commondites="searchContent.commondites"
        :curDay="null"
      ></StoreItem>
    </div>
  </div>
  <Footer />
</template>

<script>
import StoreItem from "./StoreItem.vue";
import Footer from "../../../src/components/Footer.vue";
// import Calendar from "./Calendar.vue";
import Calender2 from "./Calender2.vue";
// import { getGroups } from "../../../src/request/api";
import axios from "axios";
import { reactive, onMounted, toRefs, onUpdated } from "vue";
import { Toast } from "vant";

export default {
  props: ["searchContent"],

  setup(props) {
    function objData(id, group_name) {
      this.id = id;
      this.group_name = group_name;
      this.commondites = [];
      this.dateList = [];
    }

    const data = reactive({
      tabs_active: -1,
      resData: [],
      curDay: "",
      searchData: {
        commondites: [],
        isSearch: false,
      },
    });

    function onClickTab(name, title) {
      // console.log(name,title,"onClickTab",data.tabs_active);
    }
    onMounted(() => {
      data.searchData = props.searchContent;
      axios
        .get("/api/getGroups")
        .then((res) => {
          res.data.forEach((element) => {
            let obj = new objData(element.id, element.group_name);

            data.resData.push(obj);
          });
          if (res.data.length > 0 && data.tabs_active == -1) {
            data.tabs_active = data.resData[0].id;
          }
        })
        .catch((error) => {});
    });

    function childEvent(subData) {
      let reqParam = {};

      reqParam.dates = subData;
      reqParam.id = data.tabs_active;
      // console.log(data.tabs_active,"childEvent");
      axios
        .post("/api/getShops", reqParam)
        .then((res) => {
          let myData = res.data;
          if (myData.code == 1) {
            let curentIndex = -1;
            for (let index = 0; index < data.resData.length; index++) {
              if (data.resData[index].id == myData.msg.group_id) {
                data.resData[index].commondites = myData.msg.data;
                curentIndex = index;
              }
            }
            if (curentIndex != -1) {
              data.resData[curentIndex].dateList = [];
              for (let index = 0; index < myData.msg.data.length; index++) {
                const element = myData.msg.data[index];
                data.resData[curentIndex].dateList.push({
                  date: element.add_time,
                });
              }
              //console.log(data.resData[curentIndex].dateList.length,"data.resData[curentIndex].dateList.length");
            }
          }
        })
        .catch((error) => {});
    }

    function childEventOnly(date) {
      data.curDay = date;
    }
    return {
      ...toRefs(data),
      childEvent,
      childEventOnly,
      onClickTab,
    };
  },
  components: {
    StoreItem,
    Footer,
    // Calendar,
    Calender2,
  },
};
</script>
<style lang='less' scoped>
/deep/ 
  .van-tabs__wrap {
    background-color: #ff0b3f;
    display: flex;
    justify-content: center;

    .van-tab__text {
      font-size: 10px;
      font-weight: 800;
    }
  .van-tabs__nav{
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 30px;
    width: 95%;
    border-radius: 8px;
    padding-bottom:0px ;
    .van-tab--line::after{

        height: 100%;
        width: 1px;
        background-color: #000;
        border-radius: 1px;
    }
    .van-tabs__line{
      position: absolute;
      // top:11px;
      bottom: 5px;
      left: 0;
    }
  }
}

.store_item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
</style>