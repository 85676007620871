import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "amfe-flexible";
import axios from "axios";
import "@vant/touch-emulator";
import {
  Button,
  Search,
  NavBar,
  Field,
  Lazyload,
  Toast,
  Tab,
  Tabs,
  List,
  Swipe, 
  SwipeItem,
  ConfigProvider ,
 /* TextEllipsis,*/
  Image as VanImage,
} from "vant";
import "./common/css/base.less";
createApp(App)
  .use(Button)
  .use(Search)
  .use(NavBar)
  .use(Field)
  .use(Lazyload)
  .use(Toast)
  .use(Tab)
  .use(Tabs)
  .use(List)
  .use(SwipeItem)
  .use(Swipe)
  .use(VanImage)
  .use(ConfigProvider)
  /*.use(TextEllipsis)*/
  .use(router)
  .provide("$axios", axios)
  .mount("#app");
