<template>
<div class="myhome">
<Search @search_evnet="searchEvnet" @search_cancel="searchCencel"/>
<div class="sec">
<StoreList class="store_list" :searchContent ="data"/>

</div>

<!-- <Footer class="home_footer"/> -->
  
</div>

</template>

<script setup>
import Search from '../SearchVue/Search.vue';
import Footer from '../../src/components/Footer.vue';
import StoreList from './componets/StoreList.vue';
import { reactive } from 'vue';

    let data=reactive({
      commondites:[],
      isSearch:false
    })



    function searchEvnet(commondites){
      data.commondites=commondites;
      data.isSearch=true;
      // console.log("搜索结果:",commondites);
    }
    function searchCencel(){
      data.isSearch=false;
    }




    // return{
    //   searchEvnet
    // }

// components:{
//   Footer,
//   Search,
//   StoreList
// }

</script>
<style lang='less' scoped>
.myhome{
  min-height: 100vh; /* 使.app-container至少有100%的视口高度 */
  position: relative; /* 设置为相对定位，以便子元素可以绝对定位 */
  display: flex;
  flex-direction: column;
  .sec{
    height: 95vh;
    flex: 1; /* 这会使main区域的高度充满.app-container的剩余空间 */
  }
  .home_footer{
    height: 5vh;
    position: absolute; /* 使用绝对定位 */
  bottom: 0; /* 放置在底部 */
  left: 0; /* 左对齐 */
  width: 100%; /* 宽度占满整个父元素 */
  height: 40px; /* 高度设置 */
  // background-color: #333; /* 背景颜色 */
  font-size: 0.25rem;
  color: white; /* 文字颜色 */
  }
 


}
</style>